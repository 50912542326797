import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import BeatingHeart from '../BeatingHeart';

function Github() {
  return (
    <BeatingHeart>
      <StaticImage
        src="../../../assets/images/integrations/whisk.png"
        alt="Tumult Whisk"
        objectFit="contain"
        placeholder="none"
        width={150}
        height={140}
      />
    </BeatingHeart>
  );
}

export default Github;
